/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import Swal from "sweetalert2";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewEvent page components
import MediaImage from "./components/MediaImage";
import FormFields from "./components/FormFields";
import EventDescription from "./components/Description";

import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { updateMediaImgsApi } from "api/medias";

function UpdateMedia() {
  const { mediaID } = useParams()
  const navigate = useNavigate();

  const [formValue, setFormValue] = useState({
    eventMainImg: null,
    description: "",
    eventInfo: {},
  });

  // useEffect(() => {
  //   console.log("formValue changed:", formValue);
  // }, [formValue]);

  const handleEventMainImg = async (value) => {
    try {
      setFormValue((prevFormValue) => ({
        ...prevFormValue,
        eventMainImg: value,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateMedia = async () => {
    try {
      const eventObject = {
        mediaName: formValue?.eventInfo?.eventTitle || "",
        mediaVideo: formValue?.eventInfo?.promoVideo || "",
        mediaDescription: formValue?.description || "",
        mediaImg: formValue.eventMainImg || null
      }

      if(!eventObject?.mediaImg && eventObject?.mediaVideo === "") {
        return Swal.fire({
          title: "Selectionner la photo ou lien de la video",
          text: "",
          icon: "error"
        });
      }

      // console.log("eventObject", eventObject)

      await updateMediaImgsApi(eventObject, mediaID)

      Swal.fire({
        title: "Modifiée",
        text: "",
        icon: "success"
      });

      navigate("/media/Media_Read");
    } catch (err) {
      Swal.fire({
        title: err.response.data.error,
        text: "",
        icon: "error"
      });
    }
  };

  const handleDescriptionValue = (value) => {
    try {
      if (formValue.description !== value) {
        // Only update state if the value is different
        setFormValue((prevFormValue) => ({
          ...prevFormValue,
          description: value,
        }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleGetFormValues = (value) => {
    try {
      setFormValue((prevFormValue) => ({
        ...prevFormValue,
        eventInfo: value,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Modifier une événement
              </MDTypography>
              <MDBox mt={1} mb={2}>
                <MDTypography variant="body2" color="text"></MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <MDBox display="flex" justifyContent="flex-end">
                <MDButton variant="gradient" color="info" onClick={handleUpdateMedia}>
                  Modifier
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <MediaImage imgValue={handleEventMainImg} />
          </Grid>
          <Grid item xs={12}>
            <FormFields formValues={handleGetFormValues} />
          </Grid>
          <Grid item xs={12}>
            <EventDescription descriptionValue={handleDescriptionValue} />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UpdateMedia;
