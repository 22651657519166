/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import EventCategories from "../../modals/Categories";
import EventPlaces from "../../modals/Places";
import EventSieges from "../../modals/Sieges";
import EventStands from "../../modals/Stands";
import EventSocial from "../../modals/Socials";
import { getAllCategoriesApi } from "api/categories";
import { getAllPlacesApi } from "api/places";
import { getAllStandsApi } from "api/stands";
import { getAllSiegesApi } from "api/sieges";
import { getAllSocialsApi } from "api/socials";

function FormFields({ formValues }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.only("sm"));

  const [open, setOpen] = useState(false);
  const [openEventPlaces, setOpenEventPlaces] = useState(false);
  const [openEventSieges, setOpenEventSieges] = useState(false);
  const [openEventStand, setOpenEventStand] = useState(false);
  const [openEventSocial, setOpenEventSocial] = useState(false)

  const [categoriesData, setCategoriesData] = useState([])
  const [placesData, setPlacesData] = useState([])
  const [standsData, setStandsData] = useState([])
  const [siegesData, setSiegesData] = useState([])
  const [socialsData, setSocialsData] = useState([])

  // const [selectedCategoriesData, setselectedCategoriesData] = useState([])
  // const [selectedPlacesData, setselectedPlacesData] = useState([])
  // const [selectedStandsData, setselectedStandsData] = useState([])
  // const [selectedSiegesData, setselectedSiegesData] = useState([])

  const [formDataValues, setFormDataValues] = useState({
    eventTitle: formValues?.eventTitle,
    promoVideo: formValues?.promoVideo,
    address: formValues?.address,
    socialLink: formValues?.socialLink,
    eventPlace: formValues?.eventPlace,
    eventCategory: formValues?.eventCategory,
    eventStand: formValues?.eventStand,
    eventSiege: formValues?.eventSiege,
    startDate: formValues?.startDate,
    startTime: formValues?.startTime,
    endDate: formValues?.endDate,
    endTime: formValues?.endTime,
    eventDate: formValues?.eventDate,
    eventTime: formValues?.eventTime,
  });

  useEffect(() => {
    getInitialData()
  }, [])

  const getInitialData = async () => {
    try {
      const categories = await getAllCategoriesApi()
      const places = await getAllPlacesApi()
      const stands = await getAllStandsApi()
      const sieges = await getAllSiegesApi()
      const socials = await getAllSocialsApi()

      setCategoriesData(categories)
      setPlacesData(places.filter(el => !el.fromEvent))
      setStandsData(stands.filter(el => !el.fromEvent))
      setSiegesData(sieges)
      setSocialsData(socials.filter(el => !el.fromEvent))
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleClose = (params) => {
    setOpen(false);
  };
  const handleCloseEventPlaces = (params) => {
    setOpenEventPlaces(false);
  };
  const handleCloseEventSieges = (params) => {
    setOpenEventSieges(false);
  };
  const handleCloseEventStands = (params) => {
    setOpenEventStand(false);
  };
  const handleCloseEventSocial = (params) => {
    setOpenEventSocial(false)
  }

  const handleAddNewCategoryModal = () => {
    setOpen(true);
  };
  const handleAddNewPlaceModal = () => {
    setOpenEventPlaces(true);
  };
  const handleAddNewSiegeModal = () => {
    setOpenEventSieges(true);
  };
  const handleAddNewResellerModal = () => {
    setOpenEventStand(true);
  };
  const handleAddNewsocialModal = () => {
    setOpenEventSocial(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newFormDataValues = {
      ...formDataValues,
      [name]: value,
    };
    setFormDataValues(newFormDataValues);

    // Immediately pass the updated form data
    formValues(newFormDataValues);
  };

  const handleAutocompletePlaceChange = (event, newValue) => {
    const newFormDataValues = {
      ...formDataValues,
      eventPlace: newValue,
    };
    setFormDataValues(newFormDataValues)
    formValues(newFormDataValues);
  }
  const handleAutocompleteCategoryChange = (event, newValue) => {
    const newFormDataValues = {
      ...formDataValues,
      eventCategory: newValue,
    };
    setFormDataValues(newFormDataValues)
    formValues(newFormDataValues);
  }
  const handleAutocompleteStandChange = (event, newValue) => {
    const newFormDataValues = {
      ...formDataValues,
      eventStand: newValue,
    };
    setFormDataValues(newFormDataValues)
    formValues(newFormDataValues);
  }
  const handleAutocompleteSiegeChange = (event, newValue) => {
    const newFormDataValues = {
      ...formDataValues,
      eventSiege: newValue,
    };
    setFormDataValues(newFormDataValues)
    formValues(newFormDataValues);
  }
  const handleAutocompleteSocialChange = (event, newValue) => {
    const newFormDataValues = {
      ...formDataValues,
      socialLink: newValue,
    };
    setFormDataValues(newFormDataValues)
    formValues(newFormDataValues);
  }

  const handleRefreshData = () => {
    getInitialData()
  }

  return (
    <Card>
      <EventCategories openEventCategory={open} closeEventCategory={handleClose} refreshData={handleRefreshData} isEdite={false} openModalItemData={null} />
      <EventPlaces openEventPlace={openEventPlaces} closeEventPlace={handleCloseEventPlaces} refreshData={handleRefreshData} isEdite={false} openModalItemData={null} />
      <EventSieges openEventSiege={openEventSieges} closeEventSiege={handleCloseEventSieges} refreshData={handleRefreshData} isEdite={false} openModalItemData={null} />
      <EventStands openEventStand={openEventStand} closeEventStand={handleCloseEventStands} refreshData={handleRefreshData} isEdite={false} openModalItemData={null} />
      <EventSocial openEventSocial={openEventSocial} closeEventSocial={handleCloseEventSocial} refreshData={handleRefreshData} isEdite={false} openModalItemData={null} />
      <MDBox p={3}>
        <MDTypography variant="h5">Information d&apos;événement</MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField
                type="text"
                label="Titre de l'événement"
                name="eventTitle"
                value={formDataValues.eventTitle}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField
                type="text"
                label="Video Promotionelle"
                name="promoVideo"
                value={formDataValues.promoVideo}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField
                type="text"
                label="Adress"
                name="address"
                value={formDataValues.address}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={1}>
          <Grid container spacing={3} xs={12}>
            <Grid item xs={6}>
              <MDBox mb={3}>
                <MDBox mb={1.625} display="inline-block">
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    textTransform="capitalize"
                  >
                    Lieu de l&apos;événement:
                  </MDTypography>
                </MDBox>
                <Autocomplete
                  multiple
                  defaultValue={[]}
                  options={placesData}
                  getOptionLabel={(option) => option.name}
                  onChange={handleAutocompletePlaceChange}
                  renderInput={(params) => (
                    <FormField {...params} InputLabelProps={{ shrink: true }} />
                  )}
                />
              </MDBox>
            </Grid>
            <Grid item xs={6} mt={5}>
              <MDButton color="light" onClick={handleAddNewPlaceModal}>
                Ajouter une nouvelle lieu
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={1}>
          <Grid container spacing={3} xs={12}>
            <Grid item xs={6}>
              <MDBox mb={3}>
                <MDBox mb={1.625} display="inline-block">
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    textTransform="capitalize"
                  >
                    Categories
                  </MDTypography>
                </MDBox>
                <Autocomplete
                  multiple
                  defaultValue={[]}
                  options={categoriesData}
                  getOptionLabel={(option) => option.name}
                  onChange={handleAutocompleteCategoryChange}
                  renderInput={(params) => (
                    <FormField {...params} InputLabelProps={{ shrink: true }} />
                  )}
                />
              </MDBox>
            </Grid>
            <Grid item xs={6} mt={5}>
              <MDButton color="light" onClick={handleAddNewCategoryModal}>
                Ajouter une nouvelle categorie
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={1}>
          <Grid container spacing={3} xs={12}>
            <Grid item xs={6}>
              <MDBox mb={3}>
                <MDBox mb={1.625} display="inline-block">
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    textTransform="capitalize"
                  >
                    Stands
                  </MDTypography>
                </MDBox>
                <Autocomplete
                  multiple
                  defaultValue={[]}
                  options={standsData}
                  getOptionLabel={(option) => option.name}
                  onChange={handleAutocompleteStandChange}
                  renderInput={(params) => (
                    <FormField {...params} InputLabelProps={{ shrink: true }} />
                  )}
                />
              </MDBox>
            </Grid>
            <Grid item xs={6} mt={5}>
              <MDButton color="light" onClick={handleAddNewResellerModal}>
                Ajouter une nouvelle stand
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={1}>
          <Grid container spacing={3} xs={12}>
            <Grid item xs={6}>
              <MDBox mb={3}>
                <MDBox mb={1.625} display="inline-block">
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    textTransform="capitalize"
                  >
                    Siege
                  </MDTypography>
                </MDBox>
                <Autocomplete
                  multiple
                  defaultValue={[]}
                  options={siegesData}
                  getOptionLabel={(option) => option.name}
                  onChange={handleAutocompleteSiegeChange}
                  renderInput={(params) => (
                    <FormField {...params} InputLabelProps={{ shrink: true }} />
                  )}
                />
              </MDBox>
            </Grid>
            <Grid item xs={6} mt={5}>
              <MDButton color="light" onClick={handleAddNewSiegeModal}>
                Ajouter une nouvelle siege
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={1}>
          <Grid container spacing={3} xs={12}>
            <Grid item xs={6}>
              <MDBox mb={3}>
                <MDBox mb={1.625} display="inline-block">
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    textTransform="capitalize"
                  >
                    Réseaux sociaux
                  </MDTypography>
                </MDBox>
                <Autocomplete
                  multiple
                  defaultValue={[]}
                  options={socialsData}
                  getOptionLabel={(option) => option.name}
                  onChange={handleAutocompleteSocialChange}
                  renderInput={(params) => (
                    <FormField {...params} InputLabelProps={{ shrink: true }} />
                  )}
                />
              </MDBox>
            </Grid>
            <Grid item xs={6} mt={5}>
              <MDButton color="light" onClick={handleAddNewsocialModal}>
                Ajouter
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={1}>
          <Grid container spacing={3} xs={12}>
            <Grid item xs={6}>
              <MDBox mb={3}>
                <MDTypography variant="h6" textTransform="capitalize" fontWeight="regular">
                  Depuis
                </MDTypography>
                <FormField
                  type="date"
                  label=""
                  name="startDate"
                  value={formDataValues.startDate}
                  onChange={handleInputChange}
                />
                <FormField
                  type="time"
                  label=""
                  name="startTime"
                  value={formDataValues.startTime}
                  onChange={handleInputChange}
                />
              </MDBox>
            </Grid>
            <Grid item xs={6}>
              <MDBox mb={3}>
                <MDTypography variant="h6" textTransform="capitalize" fontWeight="regular">
                  À
                </MDTypography>
                <FormField
                  type="date"
                  label=""
                  name="endDate"
                  value={formDataValues.endDate}
                  onChange={handleInputChange}
                />
                <FormField
                  type="time"
                  label=""
                  name="endTime"
                  value={formDataValues.endTime}
                  onChange={handleInputChange}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={1}>
          <Grid container spacing={3} xs={12}>
            <Grid item xs={12}>
              <MDBox mb={3}>
                <MDTypography variant="h6" textTransform="capitalize" fontWeight="regular">
                  La date et l&apos;heure de l&apos;évenement
                </MDTypography>
                <FormField
                  type="date"
                  label=""
                  name="eventDate"
                  value={formDataValues.eventDate}
                  onChange={handleInputChange}
                />
                <FormField
                  type="time"
                  label=""
                  name="eventTime"
                  value={formDataValues.eventTime}
                  onChange={handleInputChange}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

FormFields.propTypes = {
  formValues: PropTypes.string.isRequired,
};

export default FormFields;
