/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import Analytics from "layouts/dashboards/analytics";
import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import AllProjects from "layouts/pages/profile/all-projects";
import NewUser from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import Timeline from "layouts/pages/projects/timeline";
import PricingPage from "layouts/pages/pricing-page";
import Widgets from "layouts/pages/widgets";
import RTL from "layouts/pages/rtl";
import Charts from "layouts/pages/charts";
import Notifications from "layouts/pages/notifications";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpCover from "layouts/authentication/sign-up/cover";
import ResetCover from "layouts/authentication/reset-password/cover";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-3.jpg";

import EventsList from "layouts/events/event-page";
import NewEvent from "layouts/events/new-event";
import UpdateEvent from "layouts/events/update-event";

import MediaList from "layouts/medias/media-page";
import NewMedia from "layouts/medias/new-media";
import UpdateMedia from "layouts/medias/update-media";

import PlacesList from "layouts/places/places-page";
import CategoriesList from "layouts/categories/categories-page";
import StandsList from "layouts/stands/stands-page";
import SiegesList from "layouts/sieges/sieges-page";
import SocialsList from "layouts/socials/socials-page";

import BenevolatList from "layouts/benevolat/benevolat-page";
import MessagesList from "layouts/messages/messages-page";
import ContactUsList from "layouts/contact/contact-page";
import NewsLetterList from "layouts/newsLetter/news-letter-page";

const routes = [
  {
    type: "collapse",
    name: "Brooklyn Alice",
    key: "brooklyn-alice",
    icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    collapse: [
      {
        name: "My Profile",
        key: "my-profile",
        route: "/pages/profile/profile-overview",
        component: <ProfileOverview />,
      },
      {
        name: "Settings",
        key: "profile-settings",
        route: "/pages/account/settings",
        component: <Settings />,
      },
      {
        name: "Logout",
        key: "logout",
        route: "/authentication/sign-in",
        component: <SignInBasic />,
      },
    ],
  },
  { type: "divider", key: "divider-0" },

  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Analytics",
        key: "analytics",
        route: "/dashboards/analytics",
        component: <Analytics />,
      },
      {
        name: "Sales",
        key: "sales",
        route: "/dashboards/sales",
        component: <Sales />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Évènements",
    key: "Event",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Voir",
        key: "Event_Read",
        route: "/events/Event_Read",
        component: <EventsList />,
      },
      {
        name: "Ajouter",
        key: "Event_Add",
        route: "/events/Event_Add",
        component: <NewEvent />,
      },
      {
        name: "Modifier",
        key: "Update_Event",
        route: "/events/Update_Event/:eventID",
        component: <UpdateEvent />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Médias",
    key: "website_Medias",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Voir",
        key: "Media_Read",
        route: "/media/Media_Read",
        component: <MediaList />,
      },
      {
        name: "Ajouter",
        key: "Media_Add",
        route: "/media/Media_Add",
        component: <NewMedia />,
      },
      {
        name: "Modifier",
        key: "Update_Media",
        route: "/media/Update_Media/:mediaID",
        component: <UpdateMedia />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Lieux",
    key: "website_Places",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Voir",
        key: "Places_Read",
        route: "/places/Places_Read",
        component: <PlacesList />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Categories",
    key: "website_Categories",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Voir",
        key: "Category_Read",
        route: "/Categories/Category_Read",
        component: <CategoriesList />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Stands",
    key: "website_Stands",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Voir",
        key: "Stand_Read",
        route: "/Stands/Stand_Read",
        component: <StandsList />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Siéges",
    key: "website_Sieges",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Voir",
        key: "Siege_Read",
        route: "/Sieges/Siege_Read",
        component: <SiegesList />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Réseaux sociaux",
    key: "website_Social",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Voir",
        key: "Social_Read",
        route: "/Social/Social_Read",
        component: <SocialsList />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Bénévolat",
    key: "website_Benevolat",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Voir",
        key: "Benevolat_Read",
        route: "/Benevolat/Benevolat_Read",
        component: <BenevolatList />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Messages",
    key: "website_Messages",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Voir",
        key: "Messages_Read",
        route: "/Messages/Messages_Read",
        component: <MessagesList />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Contact",
    key: "website_Contact",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Voir",
        key: "Contact_Read",
        route: "/Contact/Contact_Read",
        component: <ContactUsList />,
      },
    ],
  },
  {
    type: "collapse",
    name: "News letter",
    key: "website_News_Letter",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Voir",
        key: "News_Read",
        route: "/News/News_Read",
        component: <NewsLetterList />,
      },
    ],
  },
];

export default routes;
