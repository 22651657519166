import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";

// formik components
import { Formik, Form, Field } from "formik";
import Swal from "sweetalert2";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { Box, CircularProgress, Dialog } from "@mui/material";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { addNewSocialApi, updateSocialApi } from "api/socials";

function EventSocial({ openEventSocial, closeEventSocial, refreshData, isEdite, openModalItemData }) {
  const [isLoaded, setIsLoaded] = useState(false);

  const [socialData, setSocialData] = useState([
    {
      label: "Facebook",
      value: "Facebook",
    },
    {
      label: "WhatsApp",
      value: "WhatsApp",
    },
    {
      label: "Instagram",
      value: "Instagram",
    },
    {
      label: "Snapchat",
      value: "Snapchat",
    },
    {
      label: "TikTok",
      value: "TikTok",
    },
    {
      label: "Twitter",
      value: "Twitter",
    },
    {
      label: "Pinterest",
      value: "Pinterest",
    },
    {
      label: "iMessage",
      value: "iMessage",
    },
    {
      label: "LinkedIn",
      value: "LinkedIn",
    },
    {
      label: "Twitter",
      value: "Twitter",
    },
  ]);

  const [selectedSocialData, setSelectedSocialData] = useState(null)

  const validationSchema = Yup.object().shape({
    eventSocialUrl: Yup.string().required("Saisser URL"),
  });

  useEffect(() => {
    getInitialValue()
  }, [openModalItemData, isEdite])

  const getInitialValue = () => {
    try {
      if(isEdite) {
        const defaultSocial = socialData.find((item) => item.value === openModalItemData.name);
        setSelectedSocialData(defaultSocial);
      }
    }
    catch (err) {
      console.log(err);
    }
  }

  const handleAddNewEventSocialLink = async (value) => {
    try {
      const formValues = {
        name: selectedSocialData.value,
        url: value.eventSocialUrl,
      };

        await addNewSocialApi(formValues)
        Swal.fire({
          title: "Ajoutée",
          text: "",
          icon: "success"
        });

        refreshData()
        closeEventSocial();
    } catch (err) {
        Swal.fire({
          title: "error",
          text: "",
          icon: "error"
        });
    }
  };

  const handleUpdateEventSocialLink = async (value) => {
    try {
      const formValues = {
        name: selectedSocialData.value,
        url: value.eventSocialUrl,
      };

        await updateSocialApi(formValues, openModalItemData._id)
        Swal.fire({
          title: "Ajoutée",
          text: "",
          icon: "success"
        });

        refreshData()
        closeEventSocial();
    } catch (err) {
        Swal.fire({
          title: "error",
          text: "",
          icon: "error"
        });
    }
  };

  return (
    <div>
      <Dialog open={openEventSocial} onClose={closeEventSocial} fullWidth maxWidth="sm">
        <MDBox>
          <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
            <Grid item xs={12}>
              <Formik
                initialValues={{
                  eventSocialUrl: isEdite ? openModalItemData?.url : "",
                }}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                  isEdite ? handleUpdateEventSocialLink(values) : handleAddNewEventSocialLink(values)
                }}
              >
                <Form autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox p={3}>
                      <MDBox>
                        <MDBox mb={1.625}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} mb={1.2}>
                              <Field name="eventSocial">
                                {({ field, meta }) => (
                                  <>
                                    <Autocomplete
                                      options={socialData}
                                      getOptionLabel={(option) => option.label}
                                      value={selectedSocialData}
                                      onChange={(event, newValue) => {
                                        setSelectedSocialData(newValue);
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} label="Select your white page" />
                                      )}
                                    />
                                  </>
                                )}
                              </Field>
                            </Grid>

                            <Grid item xs={12} sm={12} mb={1.2}>
                              <Field name="eventSocialUrl">
                                {({ field, meta }) => (
                                  <>
                                    <MDInput
                                      {...field}
                                      label="URL"
                                      size="large"
                                      fullWidth
                                      error={meta.error}
                                      success={!meta.error && meta.touched}
                                    />
                                    {meta.touched && meta.error && (
                                      <MDTypography
                                        component="div"
                                        variant="caption"
                                        color="error"
                                        fontWeight="regular"
                                      >
                                        {meta.error}
                                      </MDTypography>
                                    )}
                                  </>
                                )}
                              </Field>
                            </Grid>
                          </Grid>
                        </MDBox>
                        {isLoaded ? (
                          <Box sx={{ display: "flex" }}>
                            <CircularProgress />
                          </Box>
                        ) : (
                          <MDButton type="submit" variant="gradient" color="dark">
                            {isEdite ? "Modifier" : "Ajouter"}
                          </MDButton>
                        )}
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              </Formik>
            </Grid>
          </Grid>
        </MDBox>
      </Dialog>
    </div>
  );
}

EventSocial.propTypes = {
  openEventSocial: PropTypes.bool.isRequired,
  closeEventSocial: PropTypes.func.isRequired,
};

export default EventSocial;
